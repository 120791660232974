/*  This file is part of ql_rest, a free-software/open-source library
    for utilization of QuantLib over REST */

import { useEffect, useState } from 'react';
import React from 'react';
import PricerHelper from '../helpers/PricerHelper'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { createContext, useCallback } from 'react';

const { forwardRef, useRef, useImperativeHandle } = React;

const StockPanel = React.forwardRef ((props, ref) => {

  const stockGridRef = React.useRef();
  const [stockPrices, setStockPrices] = useState([]);

  const stockGridColumnDefs = [
    {
      headerName : 'Stock Tickers',
      children: [
        { field: 'Symbol',  sortable: true, flex:1, cellStyle: { 'fontWeight': 'bold' }},
        { field: 'Price',  sortable: false, width:70,  cellStyle: {}, editable: true, cellStyle: { 'textAlign': 'right'},
          valueFormatter: params => {
                if (!isNaN(params.value))
                 return parseFloat(params.value).toFixed(2);
              } },
        { field: 'PriceChange', headerName:'PxChg',  sortable: true, width:80,cellStyle: { 'textAlign': 'right'},

          cellRenderer: params => {
             // put the value in bold;
              if ( params.value == undefined )
                return

               if ( params.value >= 0.0 )
               {
                 return ( <div>
                      <span style={{color: "rgb(152,195,121)"}}>  {params.value} </span>
                      </div> );
               } else {
                  return ( <div>
                       <span style={{color: "rgb(224,108,118)"}}> {params.value} </span>
                       </div> );
               }
             }
        },
        { field: 'PriceChangePercent', headerName:'PxChg %', sortable: true, width:80,cellStyle: { 'textAlign': 'right'},
        cellRenderer: params => {
           // put the value in bold;
            if ( params.value == undefined )
              return

             if ( params.value >= 0.0 )
             {
               return ( <div>
                    <span style={{color: "rgb(152,195,121)"}}>  {params.value} % </span>
                    </div> );
             } else {
                return ( <div>
                     <span style={{color: "rgb(224,108,118)"}}> {params.value} % </span>
                     </div> );
             }
           }
        },
        { field: 'dividendRate', headerName:'Dividend',  sortable: true,width:70, cellStyle: {}, editable: true, cellStyle: { 'textAlign': 'right'},

                cellRenderer: props => {
                  if (!isNaN(props.value))
                  {
                    return `${(props.value)}`;
                  };
                },

                valueFormatter: params => {
                      if (!isNaN(params.value))
                       return parseFloat(params.value).toFixed(2);
                    } },
                  /*{ field: 'Name',   sortable: true },*/


      ]}
  ];

  useEffect(() => {
    if ( props.symbols === undefined && stockGridRef.current.api !== undefined ) return;

    if ( props.realTimePrice != undefined )
    {
      var priced_symbols = []
      props.symbols.forEach((item, i) => {
        var priced_symbol = {}
        priced_symbol = {...item}

        var latest_price = props.realTimePrice[item['Symbol']]

        if ( latest_price !== undefined )
        {
            priced_symbol['Price'] = latest_price['market_data']['day']['l']
            priced_symbol['PriceChange'] = latest_price['market_data']['todaysChange'].toFixed(2)
            priced_symbol['PriceChangePercent'] = latest_price['market_data']['todaysChangePerc'].toFixed(2)
        }

        priced_symbols.push(priced_symbol)

        //console.log(priced_symbol)

      });

      setStockPrices(priced_symbols)

    } else {
      setStockPrices(props.symbols);
    }

  },  [props.symbols, props.realTimePrice]);

  useEffect(() => {

      if ( props.defaultTicker === undefined )
        return;

      if ( stockGridRef.current.api !== undefined )
      {
        var defaultSortModel = [
            { colId: 'Symbol', sort: 'asc', sortIndex: 0 }
          ];
        stockGridRef.current.columnApi.applyColumnState({ state: defaultSortModel });

        stockGridRef.current.api.forEachNode(node=> node.rowIndex ? 0 : node.setSelected(true))
      }

    }, [props.defaultTicker]);


  const stockGridOptions = {

    rowSelection: 'single',
    getRowHeight: (params) => 25,

    onRowSelected(event) {

      if ( !event.node.selected )
        return;

      props.getVols(event.data);
    },


    onGridReady: event => {

      props.gridReadyCallback();

      //stockGridRef.current.api.forEachNode(node=> node.rowIndex ? 0 : node.setSelected(true))

    }
  }

  const onCellEditingStopped = useCallback((updated_price) =>
  {
      props.priceChangeCallback( updated_price );
  }, []);

   return (
      <div>
        <div className="App-Row">
            <div className="ag-theme-balham-dark" style={{verticalAlign:"top",height:"35vh", width: "100%", display: "inline-block", margin: "auto", padding:"5px"}}>
            <AgGridReact
                      rowData={stockPrices}
                      columnDefs={stockGridColumnDefs}
                      gridOptions={stockGridOptions}
                      onCellEditingStopped={onCellEditingStopped}
                      ref={stockGridRef}>
            </AgGridReact>
            </div>
          </div>
      </div>
     )
  });

export default StockPanel;
