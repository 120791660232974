/*  This file is part of ql_rest, a free-software/open-source library
    for utilization of QuantLib over REST */

import React from 'react'
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart }            from 'react-chartjs-2';
import { useEffect, useState } from 'react';

import PricerHelper from '../helpers/PricerHelper'


const { forwardRef, useRef, useImperativeHandle } = React;

const SmilePanel = React.forwardRef ((props, ref) => {

  const chartRef = useRef();

  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const [options, setOptions] = useState({
    labels: [],
    datasets: [],
    maintainAspectRatio: false,
  });

  useEffect(() => {

          var strikes = [];
          var put_vols = [];
          var call_vols = [];
          var name = "N/A";
          var exerciseDate = "N/A";

          if ( props != undefined && props.volData != undefined && props.excerciseLabel != undefined) {

            for (const vol of PricerHelper.get_exercise_date_vols(props.volData,1))
            {
              put_vols.push(vol.put_vol)
              call_vols.push(vol.call_vol)
              strikes.push(vol.Strike)
            }

            exerciseDate = props.excerciseLabel.value;

          }

          const char_data = {
            labels: strikes,
            datasets: [
              {
                data : call_vols,
                fill: true,
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                label : 'Calls',
                hidden: false,
                borderWidth: 1.5,
                lineTension: 0.4
              },
              {
                data : put_vols,
                fill: true,
                backgroundColor: 'rgba(255, 99, 132,0.2)',
                borderColor: 'rgb(255, 99, 132)',
                label : 'Puts',
                hidden: false,
                borderWidth: 1.5,
                lineTension: 0.4
              }
          ]
          };

          const options = {
                 onClick: function(evt, element) {
                   if(element.length > 0)
                   {
                     props.strikeSelectedCallback(data.labels[element[0].index]);
                    }
                 },
            elements: {
                   point:{
                       radius: 1.125
                   }
               },
                 plugins: {
                   title: {
                     display: true,
                     align: 'center',
                     text: exerciseDate,
                     position: 'right',
                     color:"#ffffff",
                   },
                 }};

          setOptions(options);
          setData(char_data);

  }, [props.volData, props.excerciseLabel]);


  return <div style={{height:'34vh'}}>
    <Line data={data}
    style={{border:'1px solid #424242', backgroundColor:'#192231', position: 'relative', height:'34vh'}}
  options={options}
  ref={chartRef}/></div>;
});

export default SmilePanel;
