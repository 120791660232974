import React from "react";

//export const PRICER_URL = "http://localhost:8080";
//export const SESSION_URL = "http://localhost:8000";
//export const URL = "https://options.ustreasuries.online";
//export const PRICER_URL = "https://options.ustreasuries.online";
//export const PRICER_URL = "https://vps-48149b0f.vps.ovh.us:1443/"
export const SESSION_URL = "https://45-33-8-23.ip.linodeusercontent.com:2443"
export const PRICER_URL = "https://45-33-8-23.ip.linodeusercontent.com:1443"
//export const PRICER_URL = "http://localhost:8080";
export const CALCULATOR_COUNT = 4
export const RT_PRICE_URL = "https://45-33-8-23.ip.linodeusercontent.com:443"
